/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Services from 'components/shared/Services'
import InfoBox from 'components/shared/InfoBox'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const ContentContainer = styled.div`
  p {
    font-size: ${props => props.theme.font.size.m};
    font-weight: ${props => props.theme.font.weight.s};
    color: ${props => props.theme.color.text.secondary};
  }
`

const AboutusTemplate = ({
  data: {
    page: {
      path,
      yoastMeta,
      acf: {
        banner,
        text,
        info
      }
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />

      <Hero content={banner} />

      <div className="container pb-5">
        <div className="row">
          <div className="col-md-8">
            <ContentContainer className="pt-5 pt-md-5">
              {parse(text.description)}
            </ContentContainer>
          </div>
          <div className="col-md-4 d-flex justify-content-lg-end justify-content-center pt-3 pt-lg-0">
            <div>
              <InfoBox info={info} />
            </div>
          </div>
        </div>
        <Services noRelative />
      </div>
    </Layout>
  )
}

export default AboutusTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...AboutusFrag
    }
  }
`