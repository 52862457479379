/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledInfoBox = styled.div`
  box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
  background-color: ${props => props.theme.color.secondary};
  width: 280px;
  top: -65px;

  h2 {
    font-size: ${props => props.theme.font.size.xm};
    border-bottom: 1px solid ${props => props.theme.color.main};
    padding-bottom: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 3rem;
      bottom: -1px;
      background-color: ${props => props.theme.color.main};
    }
  }

  h3 {
    font-size: ${props => props.theme.font.size.sm};
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
  }

  @media screen and (max-width: 991px) {
    top: 0;
    
    h2 {
      &:before {
        content: none;
      }
    }
  }
`

const InfoBox = ({ className, info }) => {
  return (
    <StyledInfoBox className={`position-relative mt-lg-0 mt-md-4 mt-sm-2 px-4 py-4 ${className ? `${className}` : ``}`}>
      <h2 className="mb-4 text-uppercase color-text-gray font-weight-xl">SSB</h2>
      <h3 className="text-uppercase color-text-contrast font-weight-s">Opgericht in</h3>
      <p className="mb-4 color-text-secondary font-weight-s">{info.opgericht}</p>
      <h3 className="text-uppercase color-text-contrast font-weight-s">Aantal werknemers</h3>
      <p className="mb-4 color-text-secondary font-weight-s">{info.amount_people}</p>
    </StyledInfoBox>
  )
}

export default InfoBox