import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import SSBArrowRight from 'img/SSB_arrow_right.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
// import Slider from 'react-slick'

const StyledServices = styled.div`
  ${props => props.noRelative ? css`
    padding-top: 50px;
    padding-bottom: 50px;
  ` : css`
    top: -260px;

    @media screen and (max-width: 991px) {
      top: -160px;
    }
  `}
`

// const StyledSlider = styled(Slider)`
//   overflow: hidden;

//   .slick-slide {
//     margin: 0 5px;
//   }

//   .slick-list {
//     overflow: hidden;
//     margin: 0 -5px;
//     height: 400px;
//   }

//   .slick-track {
//     display: flex;
//   }
// `

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  transition: 0.25s;

  &:hover {
    transform: scale(1.05);
  }
`

const ImgContainer = styled.div`
  position: relative;
  height: 213px;
  overflow: hidden;
`

const Row = styled.div`
  margin-left: -100px;
  margin-right: -100px;
`

const Service = styled.div`
  box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
  width: 320px;
`

const ContentContainer = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xm};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  bottom: -20px;
  white-space: nowrap;

  @media screen and (max-width: 991px) {
    bottom: 28px;
  }

  p {
    font-size: ${props => props.theme.font.size.m};
  }
`

const Services = ({ className, noRelative }) => {
  const {
    services: {
      edges: services
    }
  } = useStaticQuery(graphql`{
    services: allWordpressWpDienst(sort: {fields: date, order: ASC}) {
      edges {
        node {
          ...ServiceFrag
        }
      }
    }
  }`)

  return (
    <StyledServices noRelative={noRelative} className={`position-relative ${className ? `${className}` : ``}`}>
      {/* <Row className="d-lg-flex d-none justify-content-between"> */}
      <Row className="row">
        {services.map(service => (
          <div className="col-md-4 d-flex justify-content-center pb-5 pb-lg-0 mb-3 mb-lg-0">
            <Service>
              <ImgContainer>
                <CustomLink to={`/diensten#${service.node.acf.content.small_name}`}>
                  <StyledImg loading="eager" fadeIn={false} fluid={service.node.acf.small_image.localFile.childImageSharp.fluid} alt="" />
                </CustomLink>
              </ImgContainer>
              <ContentContainer className="pt-4">
                <h2 className="mb-0 color-text-secondary text-uppercase font-weight-xl text-center">{service.node.title}</h2>
                <h2 className="color-text-secondary text-uppercase pb-lg-5 pb-4 font-weight-s text-center">{service.node.acf.content.type}</h2>
                <div className="d-flex justify-content-center">
                  <StyledButtonDefault to={`/diensten#${service.node.acf.content.small_name}`} className="position-absolute py-1 px-1">
                    <p className="mb-0 font-weight-xl">Meer informatie</p>
                    <img className="ml-3" src={SSBArrowRight} alt="" />
                  </StyledButtonDefault>
                </div>
              </ContentContainer>
            </Service>
          </div>
        ))}
      </Row>
      {/* 
      <Row className="d-flex d-lg-none justify-content-between">
        <StyledSlider
          infinite
          speed={200}
          slidesToShow={2}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={8000}
          nextArrow={<NextArrow />}
          prevArrow={<NextArrow />}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]}
        >
          {services.map(service => (
            <div className="d-flex justify-content-center">
              <Service>
                <ImgContainer>
                  <StyledImg fluid={service.node.acf.small_image.localFile.childImageSharp.fluid} alt="" />
                </ImgContainer>
                <ContentContainer className="pt-4">
                  <h2 className="mb-0 color-text-secondary text-uppercase font-weight-xl text-center">{service.node.title}</h2>
                  <h2 className="color-text-secondary text-uppercase font-weight-s text-center">{service.node.acf.content.type}</h2>
                  <div className="d-flex justify-content-center">
                    <StyledButtonDefault to={`/diensten#${service.node.acf.content.small_name}`} className="position-relative py-1 px-1">
                      <p className="mb-0 font-weight-xl">Meer informatie</p>
                      <img className="ml-3" src={SSBArrowRight} alt="" />
                    </StyledButtonDefault>
                  </div>
                </ContentContainer>
            
              </Service>
            </div>
          ))}
        </StyledSlider>
      </Row> */}
 
    </StyledServices>
  )
}

export default Services